import { MemoData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/memo.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PageMemosCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { channelId, espaceId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/memos/`}
        model={new MemoData({ channelId, espaceId, params })}
        name="memo"
        type="create"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageMemosCreate);
